import React, {
  useState,
  useCallback,
  useMemo,
  createContext,
  useEffect,
} from "react";

export const CmsContext = createContext();

export const CmsContextProvider = (props) => {
  //------------------------------------ constants hooks

  const [path, setPath] = useState([]);
  const [pathItems, setPathItems] = useState([]);
  const [infoData, setInfoData] = useState(null);
  const [unsubscribes, setUnsubscribes] = useState({});
  const [editFormData, setEditFormData] = useState(null);
  const [activeItemInColumn, setActiveItemInColumn] = useState(null);
  const [activeDirRef, setActiveDirRef] = useState(null);
  // const [selectedGrade, setSelectedGrade] = useState(null);
  // const [selectedScope, setSelectedScope] = useState(null);
  // const [selectedSubject, setSelectedSubject] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [selectedChapter, setSelectedChapter] = useState(null);
  // const [selectedTab, setSelectedTab] = useState(null);
  // const [selectedTip, setSelectedTip] = useState(null);
  // const [selectedExam, setSelectedExam] = useState(null);
  // const [selectedExamItem, setSelectedExamItem] = useState(null);
  // const [selectedExamHeaderItem, setSelectedExamHeaderItem] = useState(null);
  // const [selectedLectureItem, setSelectedLectureItem] = useState(null);
  // const [selectedLectureHeaderItem, setSelectedLectureHeaderItem] = useState(null);
  const [youtubeID, setYoutubeID] = useState(null);

  const getSelectedItem = useCallback(
    (type) => {
      let skippedType;
      if (type === "subject") skippedType = "category";
      let isSkipped = false;
      const isItemSelected = path.find((c) => {
        if (c.type === skippedType && c.skip) {
          isSkipped = true;
          return true;
        }
        return c.type === type;
      });
      if (!isItemSelected) return null;
      const data = pathItems.find(
        (c) => c.id === type || (isSkipped && c.id === skippedType)
      );
      return data.items.find((a) => a.id === isItemSelected.path);
    },
    [path]
  );

  const selectedGrade = useMemo(
    () => getSelectedItem("grade"),
    [getSelectedItem]
  );
  const selectedScope = useMemo(
    () => getSelectedItem("scope"),
    [getSelectedItem]
  );
  const selectedSubject = useMemo(
    () => getSelectedItem("subject"),
    [getSelectedItem]
  );
  const selectedChapter = useMemo(
    () => getSelectedItem("chapter"),
    [getSelectedItem]
  );
  const selectedTab = useMemo(() => getSelectedItem("tab"), [getSelectedItem]);
  const selectedTip = useMemo(() => getSelectedItem("tip"), [getSelectedItem]);
  const selectedExam = useMemo(
    () => getSelectedItem("practice"),
    [getSelectedItem]
  );
  const selectedExamItem = useMemo(
    () => getSelectedItem("exam_item"),
    [getSelectedItem]
  );
  const selectedExamHeaderItem = useMemo(
    () => getSelectedItem("exam_header_item"),
    [getSelectedItem]
  );
  const selectedLectureItem = useMemo(
    () => getSelectedItem("lecture_item"),
    [getSelectedItem]
  );
  const selectedLectureHeaderItem = useMemo(
    () => getSelectedItem("lecture_header_item"),
    [getSelectedItem]
  );
  const selectedCategory = useMemo(
    () => getSelectedItem("category"),
    [getSelectedItem]
  );

  const selectedItem = useMemo(
    () => ({
      selectedSubject,
      selectedGrade,
      selectedCategory,
      selectedScope,
      selectedChapter,
      selectedTab,
      selectedTip,
      selectedExam,
      selectedExamItem,
      selectedExamHeaderItem,
      selectedLectureItem,
      selectedLectureHeaderItem,
    }),
    [getSelectedItem]
  );

  const leafItem = useMemo(() => {
    const lastItem = path.at(-1);
    const lastItemList = pathItems.find((c) => c.id === lastItem.type);
    return lastItemList?.items?.find((c) => c.id === lastItem.path);
  }, [path]);

  return (
    <CmsContext.Provider
      value={{
        infoData: [infoData, setInfoData],
        unsubscribes: [unsubscribes, setUnsubscribes],
        editFormData: [editFormData, setEditFormData],
        activeItemInColumn: [activeItemInColumn, setActiveItemInColumn],
        activeDirRef: [activeDirRef, setActiveDirRef],
        // grade: [selectedGrade, setSelectedGrade],
        // scope: [selectedScope, setSelectedScope],
        // subject: [selectedSubject, setSelectedSubject],
        // category: [selectedCategory, setSelectedCategory],
        // chapter: [selectedChapter, setSelectedChapter],
        // tab: [selectedTab, setSelectedTab],
        // tip: [selectedTip, setSelectedTip],
        // exam: [selectedExam, setSelectedExam],
        // examItem: [selectedExamItem, setSelectedExamItem],
        // examHeaderItem: [selectedExamHeaderItem, setSelectedExamHeaderItem],
        // lectureItem: [selectedLectureItem, setSelectedLectureItem],
        // lectureHeaderItem: [selectedLectureHeaderItem, setSelectedLectureHeaderItem],
        youtubeId: [youtubeID, setYoutubeID],
        path: [path, setPath],
        pathItems: [pathItems, setPathItems],
        leafItem: leafItem,
        getSelectedItem: getSelectedItem,
        selectedSubject,
        selectedGrade,
        selectedCategory,
        selectedScope,
        selectedChapter,
        selectedTab,
        selectedTip,
        selectedExam,
        selectedExamItem,
        selectedExamHeaderItem,
        selectedLectureItem,
        selectedLectureHeaderItem,
      }}
    >
      {props.children}
    </CmsContext.Provider>
  );
};

export default CmsContextProvider;
