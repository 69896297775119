import React, { useState, useEffect, createContext } from "react";

export const ThemeContext = createContext();

export const ThemeContextProvider = ({
  isDarkMode,
  showLandingPage,
  setIsDarkMode,
  setOnlyLightMode,
  ...props
}) => {
  // const [isDarkMode, setIsDarkMode] = useState(_isDarkMode);

  useEffect(() => {
    localStorage.setItem("pustack-dark-theme", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider
      value={{
        theme: [isDarkMode, setIsDarkMode],
        setOnlyLightMode,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
